import React from 'react';
import * as Self from './module';
import { registerModule } from '../../app/modules/reactModules';
import Container from '../../components/global/Container';
import Heading from '../../components/global/Heading';
import RichText from '../../components/global/RichText';
import Separator from '../../components/global/Separator';
import ModuleStyle from './module.style';
import Button from '../../components/global/Button';
import { FileDownload } from '../../app/helpers/icons';
import Icon from '../../components/global/Icon';
import isEmbedded from "../../app/helpers/isEmbedded";
import {isEmbeddedUrlReplacer} from "../../app/helpers/isEmbeddedUrlReplacer";

export const getServerSideProps = async () => {
  return {
    props: {},
    maxage: 60,
    staleWhileRevalidate: 300,
  };
};

const RichTextModule = ({
  title,
  text,
  button,
  borderTop,
  borderBottom,
  variant: { value: variant },
  wlModule,
  wlLayout,
  assetLinks,
  ...p
}) => {
  const textContent = isEmbedded() ? isEmbeddedUrlReplacer(text.content) : text.content;
  const content = (
    <>
      {!!text?.content && <RichText content={textContent} />}
      {!!button?.content && (
        <Button
          className="main-button"
          href={button.href}
          nofollow={button.nofollow}
          rel={button.rel}
          target={button.target}
          variant={button.variant}
          large
        >
          {button.content}
        </Button>
      )}
      {!!assetLinks?.length && (
        <ul className="asset-links">
          {assetLinks.map(({ href, content }, i) => (
            <li key={i}>
              <Button variant="text" href={href} download>
                <Icon {...FileDownload} />
                {content}
              </Button>
            </li>
          ))}
        </ul>
      )}
    </>
  );
  if (variant === 'blue-box') {
    return (
      <ModuleStyle
        className="blue-box"
        xPaddingAsMargin
        wlLayout={wlLayout && wlLayout !== 'plain' ? wlLayout : 'centered'}
        wlModule={wlModule}
        color='white'
      >
        <div className="heading-box">
          {!!title && <Heading title={title} color="white" />}
        </div>
        <Container
          {...p}
          as="div"
          pt="20px"
          pb="20px"
          mt="0px"
          mb="0px"
          layout={false}
          color="aliceBlue"
          flex
          col
          gap="20px"
        >
          {content}
        </Container>
      </ModuleStyle>
    );
  }
  if (variant === 'sticky') {
    return (
      <ModuleStyle
        sticky
        wlModule={wlModule}
        wlLayout={wlLayout && wlLayout !== 'plain' ? wlLayout : 'centered'}
        className={variant}
        color='white'
        {...p}
      >
        {borderTop && <Separator className="separator-top" />}
        {!!title && <Heading title={title} />}
        {content}
        {borderBottom && <Separator className="separator-bottom" />}
      </ModuleStyle>
    );
  }
  return (
    <ModuleStyle
      xPaddingAsMargin
      flex
      col
      gap="20px"
      wlModule={wlModule}
      wlLayout={wlLayout && wlLayout !== 'plain' ? wlLayout : 'centered'}
      className={variant}
      color='white'
      {...p}
    >
      {borderTop && <Separator className="separator-top" />}
      {!!title && <Heading title={title} />}
      {content}
      {borderBottom && <Separator className="separator-bottom" />}
    </ModuleStyle>
  );
};

export default RichTextModule;

registerModule({ RichText: Self });
